import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/App/Layout'
import Lightbox from 'react-image-lightbox'
import BookingForm from '../components/App/BookingForm'
import { GatsbyImage } from 'gatsby-plugin-image'

const getAboutImages = graphql`
  {
    image1: file(relativePath: { eq: "blog-six.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 900
          height: 600
        )
      }
    }
  }
`

const BookingIndex = (props) => {
  const posts = []
  const [isOpen, setIsOpen] = React.useState(false)
  const [photoIndex, setPhotoIndex] = React.useState(0)
  const [src, setSrc] = React.useState('')
  const data = useStaticQuery(getAboutImages)
  //alert(JSON.stringify(data))

  return (
    <Layout location="blog">
      <div className="bread-cumbs-area bread-cumbs-bg-rooms">
        <div className="diplay-table">
          <div className="display-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <h1>{`Les suites et appartements disponibles à TALI N'NA`}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="booking" className="our-works ptb-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 text-center">
              <div className="section-title">
                <h2>Réservations</h2>
              </div>
            </div>
          </div>

          <div id="booking" className="row">
            <div className="col-lg-6">
              <div className="about-text">
                <h3>Visite au ZOO & Hébergement</h3>
                <p>
                  Pour toute visite au ZOO TALI N'NA, merci de remplir le
                  formulaire de réservation.
                </p>

                <p>
                  Pour tout besoin d'Hébergement à TALI N'NA, merci de remplir
                  également le formulaire de réservation.
                </p>
              </div>
              <GatsbyImage
                alt=""
                image={data.image1.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className="col-lg-6">
              <BookingForm />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BookingIndex
