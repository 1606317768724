import React, { useEffect } from 'react'
import { Formik, Field, Form, useFormik, FormikProvider } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { toast, Rotate } from 'react-toastify'
import { navigate } from 'gatsby'

const GATEWAY_URL = process.env.GATSBY_FWD_API_URL
const API_URL = `${GATEWAY_URL}/initpay`
const API_KEY = process.env.GATSBY_PAYGATE_API_KEY
const RETURN_URL = `https://www.talinnaminizootogo.com`
const API_URL_PAGE = `https://www.paygateglobal.com/v1/page`

const hours = [
  { key: '00:00', value: '00:00' },
  { key: '01:00', value: '01:00' },
  { key: '02:00', value: '02:00' },
  { key: '03:00', value: '03:00' },
  { key: '04:00', value: '04:00' },
  { key: '05:00', value: '05:00' },
  { key: '06:00', value: '06:00' },
  { key: '07:00', value: '07:00' },
  { key: '08:00', value: '08:00' },
  { key: '09:00', value: '09:00' },
  { key: '10:00', value: '10:00' },
  { key: '11:00', value: '11:00' },
  { key: '12:00', value: '12:00' },
  { key: '13:00', value: '13:00' },
  { key: '14:00', value: '14:00' },
  { key: '15:00', value: '15:00' },
  { key: '16:00', value: '16:00' },
  { key: '17:00', value: '17:00' },
  { key: '18:00', value: '18:00' },
  { key: '19:00', value: '19:00' },
  { key: '20:00', value: '20:00' },
  { key: '21:00', value: '21:00' },
  { key: '22:00', value: '22:00' },
  { key: '23:00', value: '23:00' },
]

const BookingForm = () => {
  const toastId = React.useRef(null)
  const [isZoo, setIsZoo] = React.useState(true)
  const [pricePerAdult, setPricePerAdult] = React.useState(5)
  const [pricePerChild, setPricePerChild] = React.useState(5)
  const [totalAmount, setTotalAmount] = React.useState(0)

  const CreateSchema = Yup.object().shape({
    bookingtype: Yup.string(),
    lastname: Yup.string().required('Saisir le nom de famille'),
    firstname: Yup.string().required('Saisir le(s) prénom(s)'),
    email: Yup.string()
      .email('Email non valide!')
      .required('Saisir une adresse email'),
    phone: Yup.string().required('Saisir un numero de telephone'),
    bookingstartdate: Yup.string().required(`Saisir la date de réservation`),
    nbadults: Yup.number().when('bookingtype', {
      is: 'zoo',
      then: Yup.number().min(1, 'Saisir au minimum 01 adulte!'),
    }),
    nbchildren: Yup.number()
      .min(0, 'Saisir au minimum 0 enfant!')
      .required(`Saisir le nombre d'enfant`),
    nbstudios: Yup.number().min(0, 'Nombre minimum 0!'),
    nbconfrooms: Yup.number().min(0, 'Nombre minimum 0!'),
    payphonenumber: Yup.number()
      .min(10000000, 'Numero invalide!')
      .required('Veuillez saisir le numéro FLOOZ/TMONEY pour payer'),
  })

  const formik = useFormik({
    initialValues: {
      bookingtype: 'zoo',
      lastname: '',
      firstname: '',
      bookingstartdate: '',
      bookingenddate: '',
      bookingstarthour: '',
      email: '',
      phone: '',
      nbadults: 0,
      nbchildren: 0,
      needresto: false,
      nbstudios: 0,
      nbconfrooms: 0,
      totaladults: 0,
      totalchildren: 0,
      totalzoo: 0,
      payphonenumber: '',
      network: 'FLOOZ',
    },
    validationSchema: CreateSchema,
    onSubmit: async (values, { resetForm }) => {
      // alert(JSON.stringify(API_KEY))
      //tx_reference, identifier (id), payment_reference, amount, datetime, payment_method, phone_number
      notify()
      axios
        .post(`${GATEWAY_URL}/booking`, values)
        .then(function (response) {
          let body = JSON.parse(response.data.body)
          let sk = body.bookingstartdate.replace('#', '')

          let keys = body.identifier + '' + sk

          let description = 'Paiement_Reservation_Zoo_Tali_nna'
          let rUrl = `${API_URL_PAGE}/?token=${API_KEY}&amount=${values.totalzoo}&description=${description}&identifier=${keys}&url=${RETURN_URL}&phone=${values.payphonenumber}&network=${values.network}`
          navigate(rUrl)


          //token&amount&description&identifier&url&phone&network

          // axios
          //   .post(API_URL, {
          //     auth_token: API_KEY,
          //     phone_number: `${values.payphonenumber}`,
          //     amount: values.totalzoo,
          //     description: 'Paiement-confirmation de réservation Zoo Tali nna',
          //     identifier: keys,
          //     network: 'FLOOZ',
          //     // url: RETURN_URL,
          //     // phone: `${values.payphonenumber}`,
          //   })
          //   .then((res) => {
          //     console.log('PAY RES : ' + JSON.stringify(res))
          //     update()
          //     resetForm()
          //   })
          //   .catch((error) => {
          //     console.log(error)
          //     updateError()
          //   })
        })
        .catch(function (error) {
          console.log(error)
          updateError()
        })
    },
  })

  const {
    errors,
    touched,
    isSubmitting,
    values,
    setFieldValue,
    handleSubmit,
    getFieldProps,
  } = formik

  const notify = () => {
    toastId.current = toast('Envoi de la demande en cours ...', {
      autoClose: false,
      closeButton: false, // Remove the closeButton
      className: 'black-background',
    })
  }

  const update = () => {
    toast.update(toastId.current, {
      render: 'Demande envoyée avec succès',
      type: toast.TYPE.SUCCESS,
      autoClose: 2000,
      closeButton: null, // The closeButton defined on ToastContainer will be used
      className: 'rotateY animated',
    })
  }

  const updateError = () => {
    toast.update(toastId.current, {
      render: 'Erreur lors de l envoi de la demande! Ressayer plus tard SVP!',
      type: toast.TYPE.ERROR,
      autoClose: 2000,
      closeButton: null, // The closeButton defined on ToastContainer will be used
      className: 'rotateY animated',
    })
  }

  //calcul automatique du montant total des adultes
  const handleChangeTA = (event) => {
    let totAdult = event.target.value * pricePerAdult
    let totZoo = formik.values.totalchildren + totAdult
    setFieldValue('totaladults', totAdult)
    setFieldValue('nbadults', event.target.value)
    setFieldValue('totalzoo', totZoo) //recalcul du total global
  }

  // calcul automatique du montant total des enfants
  const handleChangeTC = (event) => {
    let totChild = event.target.value * pricePerChild
    let totZoo = formik.values.totaladults + totChild
    setFieldValue('totalchildren', totChild)
    setFieldValue('nbchildren', event.target.value)
    setFieldValue('totalzoo', totZoo) //recalcul du total global
  }

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(`${GATEWAY_URL}/params`, {
        params: { id: '1' },
      })
      let item = res.data.Item
      setPricePerAdult(item.zooPricePerAdult)
      setPricePerChild(item.zooPricePerChild)
    }
    fetchData()
  }, [])

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <div className="row booking-form">
            <div className="col-lg-12 col-md-12">
              <div className="row booking-type">
                <div id="my-radio-group">Type de réservation: * </div>
                <div role="group" aria-labelledby="my-radio-group">
                  <label>
                    <Field
                      type="radio"
                      name="bookingtype"
                      value="zoo"
                      className="radio"
                    />
                    Zoo
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="bookingtype"
                      value="hotel"
                      className="radio"
                    />
                    Hôtel
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label htmlFor="lastname">Nom *</label>
                <Field
                  name="lastname"
                  type="text"
                  id="lastname"
                  placeholder="Saisir le nom de famille"
                  className="form-control"
                  disabled={isSubmitting}
                />
                {errors.lastname && touched.lastname ? (
                  <div className="errors">{errors.lastname}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="form-group">
                <label htmlFor="firstname">Prénom(s) *</label>
                <Field
                  name="firstname"
                  type="text"
                  id="firstname"
                  placeholder="Saisir le(s) prénom(s)"
                  className="form-control"
                  disabled={isSubmitting}
                />
                {errors.firstname && touched.firstname ? (
                  <div className="errors">{errors.firstname}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label htmlFor="phone">N° de Téléphone valide *</label>
                <Field
                  name="phone"
                  type="text"
                  id="phone"
                  className="form-control"
                  placeholder="Saisir un n° de telephone valide"
                  disabled={isSubmitting}
                />
                {errors.phone && touched.phone ? (
                  <div className="errors">{errors.phone}</div>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="form-group">
                <label htmlFor="email">Adresse mail *</label>
                <Field
                  name="email"
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Saisir une adresse email"
                  disabled={isSubmitting}
                />
                {errors.email && touched.email ? (
                  <div className="errors">{errors.email}</div>
                ) : null}
              </div>
            </div>

            {values.bookingtype === 'zoo' && (
              <>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="bookingstartdate">
                      Date de la visite *
                    </label>
                    <Field
                      name="bookingstartdate"
                      id="bookingstartdate"
                      type="date"
                      placeholder="Saisir la date de la visite"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                    {errors.bookingstartdate && touched.bookingstartdate ? (
                      <div className="errors">{errors.bookingstartdate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group">
                    <label htmlFor="bookingstarthour">
                      Heure de visite prévue *
                    </label>
                    <Field
                      name="bookingstarthour"
                      id="bookingstarthour"
                      type="text"
                      placeholder="Saisir l'heure de la visite"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                      as="select"
                    >
                      {hours.map((h, index) => (
                        <option value={h.key}>{h.value}</option>
                      ))}
                    </Field>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label htmlFor="nbadults">Nombre d'adultes *</label>
                    <Field
                      name="nbadults"
                      type="number"
                      id="nbadults"
                      className="form-control"
                      placeholder="Saisir le nombre d'adulte"
                      disabled={isSubmitting}
                      onChange={(event) => {
                        handleChangeTA(event)
                      }}
                    />
                    {errors.nbadults && touched.nbadults ? (
                      <div className="errors">{errors.nbadults}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="form-group">
                    <label htmlFor="totaladults">
                      Coût total pour les adultes ({pricePerAdult} FCFA/adulte)
                    </label>
                    <Field
                      name="totaladults"
                      type="number"
                      id="totaladults"
                      value={formik.values.totaladults}
                      className="form-control"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label htmlFor="nbchildren">Nombre d'enfants</label>
                    <Field
                      name="nbchildren"
                      id="nbchildren"
                      type="number"
                      placeholder="Saisir le nombre d'enfants"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                      onChange={(event) => {
                        handleChangeTC(event)
                      }}
                    />
                    {errors.nbchildren && touched.nbchildren ? (
                      <div className="errors">{errors.nbchildren}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-8 col-md-12">
                  <div className="form-group">
                    <label htmlFor="totalchildren">
                      Coût total pour les enfants ({pricePerChild} FCFA/enfant)
                    </label>
                    <Field
                      name="totalchildren"
                      id="totalchildren"
                      value={formik.values.totalchildren}
                      type="number"
                      className="form-control"
                      autoComplete="off"
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label htmlFor="network">FLOOZ ou TMONEY ?</label>
                    <Field
                      name="network"
                      id="network"
                      type="text"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                      as="select"
                    >
                      <option value="FLOOZ">{'FLOOZ'}</option>
                      <option value="T-Money">{'T-Money'}</option>
                    </Field>
                  </div>
                </div>

                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label htmlFor="payphonenumber">N° Flooz/TMoney</label>
                    <Field
                      name="payphonenumber"
                      id="payphonenumber"
                      type="number"
                      placeholder="Saisir le N° Mobile pour payer"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                    {errors.payphonenumber && touched.payphonenumber ? (
                      <div className="errors">{errors.payphonenumber}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="form-group">
                    <label htmlFor="totalzoo">Montant à payer</label>
                    <Field
                      style={{ foregroundcolor: 'red' }}
                      name="totalzoo"
                      id="totalzoo"
                      type="number"
                      className="form-control"
                      autoComplete="off"
                      disabled={true}
                      value={formik.values.totalzoo}
                    />
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="row booking-type">
                    <Field type="checkbox" name="needresto" />
                    <div id="my-radio-group" className="radio">
                      {'  '}Cocher si besoin probable de restauration payante
                    </div>
                  </div>
                </div>
              </>
            )}
            {values.bookingtype === 'hotel' && (
              <>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="bookingstartdate">Date début *</label>
                    <Field
                      name="bookingstartdate"
                      id="bookingstartdate"
                      type="date"
                      placeholder="Saisir la date d'arrivée"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                    {errors.bookingstartdate && touched.bookingstartdate ? (
                      <div className="errors">{errors.bookingstartdate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="bookingenddate">Date départ *</label>
                    <Field
                      name="bookingenddate"
                      id="bookingenddate"
                      type="date"
                      placeholder="Saisir la date de départ"
                      className="form-control"
                      autoComplete="off"
                      disabled={isSubmitting}
                    />
                    {errors.bookingenddate && touched.bookingenddate ? (
                      <div className="errors">{errors.bookingenddate}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="nbstudios">Nombre de studios</label>
                    <Field
                      name="nbstudios"
                      type="number"
                      id="nbstudios"
                      className="form-control"
                      placeholder="Saisir le nombre de studio à réserver"
                      disabled={isSubmitting}
                    />
                    {errors.nbstudios && touched.nbstudios ? (
                      <div className="errors">{errors.nbstudios}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="nbconfrooms">
                      Nombre de salles de réunion
                    </label>
                    <Field
                      name="nbconfrooms"
                      type="number"
                      id="nbconfrooms"
                      className="form-control"
                      placeholder="Saisir le nombre de salles de réunion"
                      disabled={isSubmitting}
                    />
                    {errors.nbconfrooms && touched.nbconfrooms ? (
                      <div className="errors">{errors.nbconfrooms}</div>
                    ) : null}
                  </div>
                </div>
              </>
            )}

            <div className="col-lg-12 col-md-12">
              <div className="center-wrap text-center">
                <div className="button">
                  <button type="submit" disabled={isSubmitting}>
                    Envoyer <i className="fa fa-long-arrow-right"></i>{' '}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  )
}

export default BookingForm
